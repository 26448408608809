@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom utility class */
.heading-custom {
  @apply text-2xl md:text-3xl lg:text-4xl font-semibold px-1;
}

.paragraph-custom {
  @apply text-sm md:text-base lg:text-xl font-light text-gray-500 py-4 px-1;
}
